import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import { catchAxios } from '../../../services/networkRequest';
import {
  disabilityParser,
  disabilityUnparser,
  formatDate,
  genderParser,
  genderUnparser,
  genericAlert,
  getChain,
} from '../../../services/helpers';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { LNMaxAge, LNMinAge, checkPostcodeValidationRequired, countryList, helperTextColor } from '../../../constants';
import RequiredInfoText from '../../RequiredInfoText';
import Error from '../../Error';
import { HelperText, InstructionTitle } from '../../Title';
import { addYears } from 'date-fns';
import { useViewport } from '../../../utilities/viewport';
import ExitButton from '../../ExitButton';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const phoneUtil = PhoneNumberUtil.getInstance();
const STEPPER_LABELS = ["Register", "Survey"];

const useStyles = makeStyles(() => ({
  stepIcon: {
    '&.MuiStepIcon-active': {
      color: '#CA218C',
    },
    '&.MuiStepIcon-completed': {
      color: '#CA218C',
    },
    '& .MuiStepIcon-text': {
      fill: '#FFF'}
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
  submitButton: {
    float: 'center',
    padding: '0.75rem 3rem',
  },
  submitButtonSelfSignUp: {
    float: 'center',
    padding: '0.75rem 3rem',
    background: '#CA218C',
    '&:hover': {
      background: '#9c1169',
    }
  },
  gameLogo: {
    width: '10rem',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '2rem',
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  privacyPolicyText: {
    fontSize: "14px"
  }
}));

export default function YoungPersonForm({ youngPerson,
                                          title,
                                          subTitle,
                                          history,
                                          entitySettings,
                                          onSubmitForm,
                                          onError,
                                          location,
                                          childPostcode,
                                          keycode
                                        }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    sur,
    f,
    l,
    d,
    g,
  } = queryString.parse(location.search);

  // Get the values from the history state for if the back button has been selected on the confirmation page
  const editValues = history.location.state;
  const childEditValues = getChain(editValues, 'child');
  const guardianEditValues = getChain(editValues, 'guardian');
  const minDateString = formatDate(addYears(new Date(), -LNMaxAge));
  const maxDateString = formatDate(addYears(new Date(), -LNMinAge));

  // Split out the guardian relationship for the form inputs - note that this doesn't come from the backend currently.
  let guardianRelationship = getChain(youngPerson, 'guardians', 0, 'relationship') || getChain(guardianEditValues, 'relationship') || '';
  const guardianRelationshipOther = guardianRelationship && guardianRelationship !== 'Parent' && guardianRelationship !== 'Other' ? guardianRelationship : '';
  if (guardianRelationshipOther) {
    guardianRelationship = 'Other';
  }
  // Convert the date format to the one expected by the form.
  let dob = getChain(youngPerson, 'dob') || getChain(childEditValues, 'dob') || d || '';
  if (dob) {
    dob = formatDate(new Date(dob));
  }

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, [entitySettings]);
  const confirmation = callback => {
    genericAlert(t(translationKey.AlertTitleCancelYoungForm), t(translationKey.AlertBodyCancelYoungForm), callback);
  };

  const country = getChain(youngPerson, 'country') || 'United Kingdom';
  let [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(country);

  const { views: { mobile, tablet } } = useViewport();
  const standardComponentXS = mobile ? 12 : 6;

  const selfSignupCountry = getChain(entitySettings, 'postcode_restriction_settings', 'country')

  const handleCheckboxChange = (value, values, setFieldValue) => {
    const newSet = new Set(values.child.disability_types);
    
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    
    setFieldValue('child.disability_types', newSet);
  };

  const getDisabilityTypes = () => {
    const hasDisability = getChain(youngPerson, 'has_disability') || getChain(childEditValues, 'disability');
    if (!hasDisability) return new Set();

    const disabilityTypes = getChain(youngPerson, 'disability_types') || '';
    return new Set(disabilityTypes.split(',').filter(Boolean));
  }

  return (
    <Container component="main" maxWidth="lg">
      <Card className={ classes.card }>
        <CardContent>
          {keycode && 
            <div>
              <Stepper activeStep={0} alternativeLabel>
              {
                STEPPER_LABELS.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{
                        classes: { root: classes.stepIcon },
                      }}>
                      {label}
                    </StepLabel>
                  </Step>
                ))
              }
              </Stepper>
            </div>}
          {
            entitySettings &&
            <Grid container justify="center">
              <img src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" className={ classes.gameLogo } />
            </Grid>
          }
          <Formik
            enableReinitialize={entitySettings}
            initialValues={ {
              acceptedTermsAndConditions: false,
              child: {
                first_name: getChain(youngPerson, 'first_name') || getChain(childEditValues, 'first_name') || f || '',
                last_name: getChain(youngPerson, 'last_name') || getChain(childEditValues, 'last_name') || l ||'',
                dob: dob,
                ethnicity: getChain(youngPerson, 'ethnicity') || getChain(childEditValues, 'ethnicity') || '',
                disability: disabilityParser(getChain(youngPerson, 'has_disability')) || disabilityParser(getChain(childEditValues, 'disability')),
                disability_types: getDisabilityTypes(),
                gender: genderParser(getChain(youngPerson, 'gender') || getChain(childEditValues, 'gender') || g || ''),
                post_code: childPostcode || getChain(youngPerson, 'post_code') || getChain(childEditValues, 'post_code'),
                country: selfSignupCountry || getChain(youngPerson, 'country') || getChain(childEditValues, 'country') || 'United Kingdom',
                service_user_reference: getChain(youngPerson, 'service_user_reference') || getChain(childEditValues, 'service_user_reference') || sur,
                custom_entry_one_query: getChain(entitySettings, 'custom_entry_one', 'custom_entry_one_name'),
                custom_entry_one_response: getChain(youngPerson, 'custom_entry_one_response') || getChain(childEditValues, 'custom_entry_one_response' || ''),
                custom_entry_two_query: getChain(entitySettings, 'custom_entry_two', 'custom_entry_two_name'),
                custom_entry_two_response: getChain(youngPerson, 'custom_entry_two_response') || getChain(childEditValues, 'custom_entry_two_response' || ''),
                custom_entry_three_query: getChain(entitySettings, 'custom_entry_three', 'custom_entry_three_name'),
                custom_entry_three_response: getChain(youngPerson, 'custom_entry_three_response') || getChain(childEditValues, 'custom_entry_three_response' || ''),
                test_account: getChain(youngPerson, 'test_account') || getChain(childEditValues, 'test_account') || false,
              },
              guardian: {
                id: getChain(youngPerson, 'guardians', 0, 'id') || undefined,
                email: getChain(youngPerson, 'guardians', 0, 'email') || getChain(guardianEditValues, 'email') || '',
                telephone: getChain(youngPerson, 'guardians', 0, 'telephone') || getChain(guardianEditValues, 'telephone') || '',
                relationship: guardianRelationship,
                relationship_other: guardianRelationshipOther,
                first_name: getChain(youngPerson, 'guardians', 0, 'first_name') || getChain(guardianEditValues, 'first_name') || '',
                last_name: getChain(youngPerson, 'guardians', 0, 'last_name') || getChain(guardianEditValues, 'last_name') || '',
                post_code: getChain(youngPerson, 'guardians', 0, 'post_code') || getChain(guardianEditValues, 'post_code') || '',
                lumi_nova_visibility_response: getChain(youngPerson, 'guardians', 0, 'lumi_nova_visibility_response') || '',
              },
            } }

            validate={ values => {
              const errors = {};
              if (values.guardian.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.guardian.email)) {
                errors.email = t(translationKey.ErrorInvalidEmail);
              }
              if (!values.child.first_name) {
                errors.childFirstName = t(translationKey.ErrorSpecifyFirstName);
              }
              if (!values.child.last_name) {
                errors.childLastName = t(translationKey.ErrorSpecifyLastName);
              }
              if (!values.child.dob) {
                errors.dob = t(translationKey.ErrorSpecifyDOB);
              } else {
                const selectedTimestamp = new Date(values.child.dob).getTime();
                const currentTimestamp = new Date().getTime();
                const maxTimestamp = new Date(currentTimestamp);
                const minTimestamp = new Date(currentTimestamp);

                maxTimestamp.setFullYear(maxTimestamp.getFullYear() - LNMinAge);
                minTimestamp.setFullYear(minTimestamp.getFullYear() - LNMaxAge);

                const maxTime = maxTimestamp.getTime();
                const minTime = minTimestamp.getTime();

                if (selectedTimestamp > maxTime || selectedTimestamp < minTime) {
                  errors.dob = t(translationKey.ErrorSpecifyDOB);
                }
              }
              if (!values.child.gender) {
                errors.gender = t(translationKey.ErrorSpecifyGender)
              }
              if (!values.child.ethnicity) {
                errors.ethnicity = t(translationKey.ErrorSpecifyEthnicity)
              }
              if (!values.child.disability) {
                errors.disability = t(translationKey.ErrorSpecifyDisability)
              }
              if(values.child.disability === 'true' && values.child.disability_types.size === 0)
              {
                errors.disability_types = t(translationKey.ErrorSpecifyDisabilityTypes)
              }
              if (entitySettings && getChain(entitySettings, "lumi_nova_visibility", "show") && !values.guardian.lumi_nova_visibility_response) {
                errors.lumi_nova_visibility_response = t(translationKey.ErrorSpecifyMarketingVisibilityResponse)
              }
              if (!values.guardian.telephone) {
                errors.number = t(translationKey.ErrorSpecifyNumber);
              } else {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(values.guardian.telephone, 'GB');
                  if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                    errors.number = t(translationKey.ErrorValidPhoneNumber);
                  }
                } catch (err) {
                  errors.number = t(translationKey.ErrorValidPhoneNumber);
                }
              }
              if (!values.guardian.first_name) {
                errors.guardianFirstName = t(translationKey.ErrorSpecifyGuardianFirstName);
              }
              if (!values.guardian.last_name) {
                errors.guardianLastName = t(translationKey.ErrorSpecifyGuardianLastName);
              }
              if (!values.guardian.relationship) {
                errors.relationship = t(translationKey.ErrorSpecifyRelationship);
              }

              [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(values.child.country);

              if (values.guardian.post_code) {
                values.guardian.post_code = values.guardian.post_code.toUpperCase();
              }
              if (values.child.post_code) {
                values.child.post_code = values.child.post_code.toUpperCase();
              }

              return errors;
            } }
            onSubmit={ (values, { setSubmitting, setFieldError, resetForm }) => {
              setSubmitting(true);
              // Reverse date format for the backend
              const reformattedDoB = formatDate(values.child.dob);
              // Convert phone number into international format
              const number = phoneUtil.parseAndKeepRawInput(values.guardian.telephone, 'GB');
              const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
              const relationship = values.guardian.relationship === 'Other' && values.guardian.relationship_other ? values.guardian.relationship_other : values.guardian.relationship;
              const updatedValues = update(values, {
                child: {
                  dob: { $set: reformattedDoB },
                  disability: { $set: disabilityUnparser(values.child.disability) },
                  disability_types: {$set: values.child.disability === 'true' ? [...values.child.disability_types].join(',') : ''},
                  gender: { $set: genderUnparser(values.child.gender) },
                },
                guardian: {
                  telephone: { $set: internationalNumber },
                  relationship: { $set: relationship },
                },
              });
              onSubmitForm(updatedValues)
                .then(resetForm)
                .catch(err => {
                  const errorResponse = getChain(err, 'response', 'data');

                  if (entitySettings) {
                    onError(errorResponse);
                  } else if (getChain(err, 'response', 'status') === 402) {
                    const { title, body: message } = errorResponse;

                    genericAlert({
                      title,
                      message,
                      negativeTitle: t(translationKey.TitleBack),
                    });
                  } else {
                    catchAxios(setFieldError)(err);
                    setSubmitting(false);
                  }

                });
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 setFieldValue,
                 isSubmitting,
                 /* and other goodies */
               }) => {
              return (
                <form onSubmit={ handleSubmit } autoComplete="off">
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    {
                      !entitySettings &&
                      <div style={ { display: 'grid', placeItems: 'end', width: '100%' } }>
                        <ExitButton history={ history } confirmation={ confirmation } />
                      </div>
                    }
                    <Grid item xs={ 12 }>
                      <Typography className={ classes.title } component="h1" align="center" variant="body1">
                        { title }
                      </Typography>
                      <Typography className={ classes.subTitle } component="h2" align="center" variant="body1">
                        { subTitle }
                      </Typography>
                    </Grid>
                  </Grid>
                  <RequiredInfoText align="left" />
                  <InstructionTitle part={ 1 }>
                    {t(translationKey.TitleYoungPersonInformation)}
                  </InstructionTitle>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label={t(translationKey.LabelYoungFirstName)}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="child.first_name"
                        autoFocus
                        onBlur={ handleBlur }
                        value={ values.child.first_name }
                        helperText={ errors.childFirstName && touched.child && touched.child.first_name && errors.childFirstName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ f }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label={t(translationKey.LabelYoungLastName)}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.last_name"
                        onBlur={ handleBlur }
                        value={ values.child.last_name }
                        helperText={ errors.childLastName && touched.child && touched.child.last_name && errors.childLastName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ l }
                      />
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={ classes.textField }
                          label={t(translationKey.LabelYoungPersonDOB)}
                          margin="normal"
                          inputVariant='outlined'
                          variant="outlined"
                          format='dd/MM/yyyy'
                          placeholder='dd/mm/yyyy'
                          inputMode='numeric'
                          maxDate={maxDateString}
                          minDate={minDateString}
                          InputProps={{
                            inputMode: "numeric",
                            pattern: '[0-9]*'
                          }}
                          InputLabelProps={{
                            shrink: true,
                            // readOnly: false
                          }}
                          KeyboardButtonProps={{
                            style: {display: "none"}
                          }}
                          name="child.dob"
                          onBlur={ handleBlur }
                          value={ values.child.dob ? new Date(values.child.dob) : null }
                          helperText={ (errors.dob && touched.child && touched.child.dob) ? errors.dob :  "Day, Month, Year"}
                          FormHelperTextProps={ { style: { color: (errors.dob && touched.child && touched.child.dob) ? helperTextColor : "#0000008a" } } }
                          onChange={ (value) => setFieldValue("child.dob",value) }
                          required={ true }
                          disabled={ d } 
                          // TextFieldComponent={(props) => <TextField {...props} variant="outlined" />}
                          
                        >
                        </KeyboardDatePicker>  
                      </MuiPickersUtilsProvider>
                      {/* <TextField
                        className={ classes.textField }
                        label={t(translationKey.LabelYoungPersonDOB)}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          max: maxDateString,
                          min: minDateString,
                        }}
                        InputLabelProps={ {
                          shrink: true,
                        } }
                        type="date"
                        name="child.dob"
                        onBlur={ handleBlur }
                        value={ values.child.dob }
                        helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                        disabled={ d }
                      /> */}
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <FormControl required={true} variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="gender-select-outlined-label">
                          {t(translationKey.LabelGender)}
                        </InputLabel>
                        <Select
                          required={true}
                          labelId="gender-select-outlined-label"
                          id="gender-select-outlined"
                          name="child.gender"
                          value={ values.child.gender }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                          disabled={ g }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ t(translationKey.LabelMale) }>{t(translationKey.LabelMale)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelFemale) }>{t(translationKey.LabelFemale)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelNonBinary) }>{t(translationKey.LabelNonBinary)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelOtherNotListed) }>{t(translationKey.LabelOtherNotListed)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelNA) }>{t(translationKey.LabelNA)}</MenuItem>
                        </Select>
                        <HelperText>
                          { errors.gender && touched.child && touched.child.gender && errors.gender }
                        </HelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <FormControl required={true} variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="ethnicity-select-outlined-label">
                          {t(translationKey.LabelEthnicity)}
                        </InputLabel>
                        <Select
                          required={true}
                          labelId="ethnicity-select-outlined-label"
                          id="ethnicity-select-outlined"
                          name="child.ethnicity"
                          value={ values.child.ethnicity }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityWhiteBritish) }>{t(translationKey.LabelEthnicityWhiteBritish)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityWhiteIrish)}>{t(translationKey.LabelEthnicityWhiteIrish)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityWhiteOther) }>{t(translationKey.LabelEthnicityWhiteOther)}</MenuItem>

                          <MenuItem value={ t(translationKey.LabelEthnicityMixedWhiteAndCaribbean) }>{t(translationKey.LabelEthnicityMixedWhiteAndCaribbean)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityMixedWhiteAndAfrican) }>{t(translationKey.LabelEthnicityMixedWhiteAndAfrican)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityMixedWhiteAndAsian) }>{t(translationKey.LabelEthnicityMixedWhiteAndAsian)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityMixedOther) }>{t(translationKey.LabelEthnicityMixedOther)}</MenuItem>

                          <MenuItem value={ t(translationKey.LabelEthnicityAsianIndian) }>{t(translationKey.LabelEthnicityAsianIndian)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityAsianPakistani) }>{t(translationKey.LabelEthnicityAsianPakistani)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityAsianBangladeshi) }>{t(translationKey.LabelEthnicityAsianBangladeshi)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityAsianOther) }>{t(translationKey.LabelEthnicityAsianOther)}</MenuItem>

                          <MenuItem value={ t(translationKey.LabelEthnicityBlackCaribbean) }>{t(translationKey.LabelEthnicityBlackCaribbean)}</MenuItem>
                          <MenuItem value={ t(translationKey.LLabelEthnicityBlackAfrican) }>{t(translationKey.LLabelEthnicityBlackAfrican)}</MenuItem>
                          <MenuItem value={ t(translationKey.LLabelEthnicityBlackOther) }>{t(translationKey.LLabelEthnicityBlackOther)}</MenuItem>

                          <MenuItem value={ t(translationKey.LabelEthnicityOtherChinese) }>{t(translationKey.LabelEthnicityOtherChinese)}</MenuItem>
                          <MenuItem value={ t(translationKey.LabelEthnicityOther) }>{t(translationKey.LabelEthnicityOther)}</MenuItem>

                          <MenuItem value={ t(translationKey.LabelNA) }>{t(translationKey.LabelNA)}</MenuItem>
                        </Select>
                        <HelperText>
                          { errors.ethnicity && touched.child && touched.child.ethnicity && errors.ethnicity }
                        </HelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ mobile ? 6 : 3 }>
                      <FormControl variant="outlined" className={ classes.formControl }>
                        <InputLabel ref={ inputLabel } id="country-select-outlined-label">
                          {t(translationKey.LabelCountry)}
                        </InputLabel>
                        <Select
                          labelId="country-select-outlined-label"
                          id="country-select-outlined"
                          name="child.country"
                          value={ values.child.country }
                          className={ classes.textField }
                          labelWidth={ labelWidth }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                          disabled={ selfSignupCountry }
                        >
                          {
                            countryList.map((country, i) =>
                              <MenuItem value={ country } key={ i }>{ country }</MenuItem>,
                            ) }
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={ mobile ? 6 : 3 }>
                      <TextField
                        className={ classes.textField }
                        inputProps={ postcodeValidation }
                        label={t(translationKey.LabelPostcode)}
                        required={ postcodeValidationRequired }
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.post_code"
                        onBlur={ handleBlur }
                        value={ values.child.post_code }
                        onChange={ handleChange }
                        disabled={ childPostcode }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS } className={ classes.disabilitySpace }>
                      <FormControl required={true} component="fieldset" className={ classes.formControl }>
                        <FormLabel component="legend">{t(translationKey.LabelDisabilityEnquiry)}</FormLabel>
                        <RadioGroup
                          required={true}
                          aria-label="disability"
                          name="child.disability"
                          value={ values.child.disability }
                          onChange={ handleChange }>
                          <FormControlLabel value={ 'true' } control={ <Radio /> } label={t(translationKey.ChoiceLabelYes)} />
                          <FormControlLabel value={ 'false' } control={ <Radio /> } label={t(translationKey.ChoiceLabelNo)} />
                          <FormControlLabel value={ 'n/a' } control={ <Radio /> } label={t(translationKey.ChoiceLabelNA)} />
                        </RadioGroup>
                        <HelperText>
                          { errors.disability && touched.child && touched.child.disability && errors.disability }
                        </HelperText>
                      </FormControl>
                      {values.child.disability === 'true' && 
                      <FormControl required={values.child.disability === 'true'} component="fieldset" className={ classes.formControl }>
                        <FormLabel component="legend">{t(translationKey.LabelDisabilityEnquiryTypes)}</FormLabel>
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityADHD) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityADHD))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityADHD), values, setFieldValue)}
                            /> } 
                            label={t(translationKey.LabelDisabilityADHD)} />
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityAutism) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityAutism))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityAutism), values, setFieldValue)}
                            /> } 
                            label={t(translationKey.LabelDisabilityAutism)} />
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityLearningDisability) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityLearningDisability))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityLearningDisability), values, setFieldValue)}
                            />} 
                            label={t(translationKey.LabelDisabilityLearningDisability)} />
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityOtherSEND) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityOtherSEND))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityOtherSEND), values, setFieldValue)}
                            /> } 
                            label={t(translationKey.LabelDisabilityOtherSEND)} />
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityOther) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityOther))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityOther), values, setFieldValue)}
                            /> } 
                            label={t(translationKey.LabelDisabilityOther)} />
                          <FormControlLabel 
                            value={ t(translationKey.LabelDisabilityPreferNotToSay) } 
                            control={ <Checkbox 
                              checked={values.child.disability_types.has(t(translationKey.LabelDisabilityPreferNotToSay))}
                              onChange={() => handleCheckboxChange(t(translationKey.LabelDisabilityPreferNotToSay), values, setFieldValue)}
                            /> } 
                            label={t(translationKey.LabelDisabilityPreferNotToSay)} />
                        <HelperText>
                          { errors.disability_types }
                        </HelperText>
                      </FormControl>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS }>
                      {
                        (!entitySettings || getChain(entitySettings, 'service_user_reference_field', 'show')) &&
                        <TextField
                          className={ classes.textField }
                          label={ getChain(entitySettings, 'service_user_reference_field', 'custom_title') || t(translationKey.LabelServiceUserReference) }
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.service_user_reference"
                          onBlur={ handleBlur }
                          value={ values.child.service_user_reference }
                          onChange={ handleChange }
                          disabled={ sur }
                          required={ keycode }
                        />
                      }
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      {
                        getChain(entitySettings, 'custom_entry_one', 'show') &&
                        <TextField
                          className={ classes.textField }
                          label={ getChain(entitySettings, 'custom_entry_one', 'custom_entry_one_name') || t(translationKey.LabelCustomEntryOne) }
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.custom_entry_one_response"
                          onBlur={ handleBlur }
                          value={ values.child.custom_entry_one_response }
                          onChange={ handleChange }
                          required={keycode}
                        />
                      }
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      {
                        getChain(entitySettings, 'custom_entry_two', 'show') &&
                        <TextField
                          className={ classes.textField }
                          label={ getChain(entitySettings, 'custom_entry_two', 'custom_entry_two_name') || t(translationKey.LabelCustomEntryTwo) }
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.custom_entry_two_response"
                          onBlur={ handleBlur }
                          value={ values.child.custom_entry_two_response }
                          onChange={ handleChange }
                          required={keycode}
                        />
                      }
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      {
                        getChain(entitySettings, 'custom_entry_three', 'show') &&
                        <TextField
                          className={ classes.textField }
                          label={ getChain(entitySettings, 'custom_entry_three', 'custom_entry_three_name') || t(translationKey.LabelCustomEntryThree) }
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.custom_entry_three_response"
                          onBlur={ handleBlur }
                          value={ values.child.custom_entry_three_response }
                          onChange={ handleChange }
                          required={keycode}
                        />
                      }
                    </Grid>
                  </Grid>

                  <InstructionTitle part={ 2 }>
                    {t(translationKey.LabelParentGuardianInformation)}
                  </InstructionTitle>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label={t(translationKey.LabelGuardianFirstName)}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="guardian.first_name"
                        onBlur={ handleBlur }
                        value={ values.guardian.first_name }
                        helperText={ errors.guardianFirstName && touched.guardian && touched.guardian.first_name && errors.guardianFirstName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        label={t(translationKey.LabelGuardianLastName)}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="guardian.last_name"
                        onBlur={ handleBlur }
                        value={ values.guardian.last_name }
                        helperText={ errors.guardianLastName && touched.guardian && touched.guardian.last_name && errors.guardianLastName }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>

                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required={ true }
                        type="email"
                        fullWidth
                        id="email"
                        label={t(translationKey.LabelGuardianEmail)}
                        name="guardian.email"
                        autoComplete="email"
                        value={ values.guardian.email }
                        helperText={ errors.email && touched.guardian && touched.guardian.email && errors.email }
                        FormHelperTextProps={ { style: { color: helperTextColor } } }
                        onChange={ handleChange }
                        onBlur={ handleBlur }
                      />
                    </Grid>
                    <Grid item xs={ standardComponentXS }>
                      <TextField
                        className={ classes.textField }
                        inputProps={ postcodeValidation }
                        label={t(translationKey.LabelGuardianPostcode)}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="guardian.post_code"
                        onBlur={ handleBlur }
                        value={ values.guardian.post_code }
                        onChange={ handleChange }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid container spacing={2}>
                      <Grid item xs={ standardComponentXS }>
                        <FormControl component="fieldset" className={ classes.formControl }>
                          <FormLabel component="legend">{t(translationKey.LabelRelationshipToYoungPerson)}</FormLabel>
                          <Grid container alignItems="flex-end">
                            <Grid item xs={ mobile ? 3 : tablet ? 2 : 1 } style={{marginRight: mobile ? '0px' : '22px'}}>
                              <RadioGroup
                                aria-label={t(translationKey.LabelRelationship)}
                                name="guardian.relationship"
                                value={ values.guardian.relationship }
                                onChange={ handleChange }
                                required={ true }>
                                <FormControlLabel value="Parent" control={ <Radio /> } label={t(translationKey.LabelParent)} />
                                <FormControlLabel value="Other" control={ <Radio /> } label={t(translationKey.LabelOther)} />
                              </RadioGroup>
                            </Grid>
                            <Grid item xs={ mobile ? 8 : 6 } style={ { padding: '12px' } }>
                              <TextField
                                variant="standard"
                                type="text"
                                name="guardian.relationship_other"
                                fullWidth
                                disabled={ (values.guardian.relationship !== 'Other') }
                                onBlur={ handleBlur }
                                value={ values.guardian.relationship_other }
                                onChange={ handleChange }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      {
                        getChain(entitySettings, 'lumi_nova_visibility', 'show') &&
                        <Grid item xs={ standardComponentXS }>
                          <FormControl required={keycode} variant="outlined" className={ classes.formControl }>
                            <InputLabel ref={ inputLabel } id="where-did-you-hear-label">
                              {t(translationKey.LabelMarketingVisibility)}
                            </InputLabel>
                            <Select
                              required={keycode}
                              labelId="where-did-you-hear-label"
                              id="where-did-you-hear-select-outlined"
                              name="guardian.lumi_nova_visibility_response"
                              value={ values.guardian.lumi_nova_visibility_response }
                              className={ classes.textField }
                              labelWidth={ labelWidth }
                              onChange={ handleChange }
                              onBlur={ handleBlur }
                            >
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityAdvertisement)}>{t(translationKey.LabelMarketingVisibilityAdvertisement)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityCharityCommunity)}>{t(translationKey.LabelMarketingVisibilityCharityCommunity)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityGpHealthcare)}>{t(translationKey.LabelMarketingVisibilityGpHealthcare)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityMentalHealthPro)}>{t(translationKey.LabelMarketingVisibilityMentalHealthPro)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityNewsMedia)}>{t(translationKey.LabelMarketingVisibilityNewsMedia)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilitySchool)}>{t(translationKey.LabelMarketingVisibilitySchool)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilitySearchEngine)}>{t(translationKey.LabelMarketingVisibilitySearchEngine)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilitySocialMedia)}>{t(translationKey.LabelMarketingVisibilitySocialMedia)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityFamilyFriend)}>{t(translationKey.LabelMarketingVisibilityFamilyFriend)}</MenuItem>
                              <MenuItem value={t(translationKey.LabelMarketingVisibilityOther)}>{t(translationKey.LabelMarketingVisibilityOther)}</MenuItem>
                            </Select>
                            <HelperText>
                              { errors.lumi_nova_visibility_response && touched.guardian && touched.guardian.lumi_nova_visibility_response && errors.lumi_nova_visibility_response }
                            </HelperText>
                          </FormControl>
                        </Grid>
                      }
                    </Grid>

                    <Grid container style={ { marginTop: '20px' } }>
                      <FormControl component="fieldset" className={ classes.formControl }>
                        {
                          entitySettings &&
                          <FormLabel component="legend" style={ { textAlign: 'left' } }>
                            {t(translationKey.LabelProvidePhoneNumberForGameProgress)}
                          </FormLabel>
                        }
                        <Grid item xs={ standardComponentXS }>
                          <TextField
                            className={ classes.textField }
                            label={t(translationKey.LabelGuardianMobile)}
                            margin="normal"
                            variant="outlined"
                            type="tel"
                            name="guardian.telephone"
                            onBlur={ handleBlur }
                            value={ values.guardian.telephone }
                            helperText={ errors.number && touched.guardian && touched.guardian.telephone && errors.number }
                            FormHelperTextProps={ { style: { color: helperTextColor } } }
                            onChange={ handleChange }
                            required={ true }
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    {
                      entitySettings && keycode &&
                      <Grid>
                        <FormControlLabel
                          className={ classes.formControl }
                          control={
                            <Checkbox
                              color="primary"
                              inputProps={ { 'aria-label': t(translationKey.LabelSecondaryCheckbox) } }
                              name="acceptedTermsAndConditions"
                              value={ values.acceptedTermsAndConditions }
                              checked={ values.acceptedTermsAndConditions }
                              onChange={ handleChange }
                            />
                          }
                          label={
                            <span>
                              {t(translationKey.LabelTickTermsAndConditionsIAgreeTo)}
                              <a rel="noopener noreferrer"
                                 target="_blank"
                                 href="https://www.bfb-labs.com/lumi-nova-terms-and-conditions">
                                {t(translationKey.LabelTermsAndConditions)}
                              </a>
                              {t(translationKey.LabelTickTermsAndConditionsIHaveReadAndUnderstand)}
                              <a rel="noopener noreferrer"
                                 target="_blank"
                                 href="https://www.bfb-labs.com/lumi-nova-privacy-policy ">
                                { t(translationKey.LabelPrivacyPolicy) }
                              </a>
                            </span>
                          }
                        />


                      </Grid>

                    }

                    {
                      !keycode &&
                      <Grid>
                        <FormControlLabel
                          className={ classes.formControl }
                          value="TestAccount"
                          control={
                            <Checkbox
                              color="primary"
                              inputProps={ { 'aria-label': t(translationKey.LabelSecondaryCheckbox) } }
                              name="child.test_account"
                              value={ values.child.test_account }
                              checked={ values.child.test_account }
                              onChange={ handleChange }
                            />
                          }
                          label={t(translationKey.LabelTickIfTestAccount)} />
                      </Grid>
                    }

                    <HelperText>
                      { errors.relationship && touched.guardian && touched.guardian.relationship && errors.relationship }
                    </HelperText>
                  </Grid>

                  <Grid container spacing={ 2 } style={ { marginTop: '2rem' } }>
                    <Grid item xs={ 12 }>
                      {
                        keycode ?
                          <Grid>
                            <Grid item xs={12}>
                              <Button variant="contained" color="secondary" type="primary" disabled={ isSubmitting || !values.acceptedTermsAndConditions }
                                      className={ classes.submitButtonSelfSignUp } style={{ textTransform: 'none' }}>
                                {t(translationKey.ButtonRegister)}
                              </Button>
                            </Grid>
                          </Grid>
                          :
                          <Button variant="contained" color="primary" type="primary" disabled={ isSubmitting }
                                  className={ classes.submitButton } style={{ textTransform: 'uppercase' }}>

                            {t(translationKey.ButtonSave)}
                          </Button>
                      }
                    </Grid>
                    <Grid item xs={ 12 }>
                      <Error style={{ textAlign: 'left' }} message={ errors['network'] } />
                    </Grid>
                  </Grid>
                </form>
              );
            } }
          </Formik>
        </CardContent>
      </Card>
      { keycode && <Grid className={ classes.privacyPolicyText }>
        <Grid item xs={ 12 } style={ { paddingTop: '1rem' } }>
                                <span>
                                  { t(translationKey.LabelYourInformationIsSecure) }
                                </span>
        </Grid>

        <Grid item xs={ 12 } style={ { paddingTop: '1rem' } }>
                                <span>
                                  { t(translationKey.LabelPrivacyPolicySelfSignup) }
                                  <a rel="noopener noreferrer"
                                     target="_blank"
                                     href="https://www.bfb-labs.com/lumi-nova-privacy-policy ">
                                      { t(translationKey.LabelPrivacyPolicy) }
                                  </a>
                                </span>
        </Grid>

        <Grid item xs={ 12 }>
                                <span>
                                  { t(translationKey.LabelContactUs) }
                                  <a rel="noopener noreferrer"
                                     target="_blank"
                                     href="https://www.bfb-labs.com/contact">
                                  contact us
                                  </a>
                                </span>
        </Grid>
      </Grid> }
    </Container>
  );
}
